import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { CalendarListDataResponse, CalendarListResponse, FetchEventsParams } from '@/models/calendar';
import { ToastProgrammatic as Toast } from 'buefy';

@Module({
    namespaced: true,
    dynamic: true,
    name: `calendar.list`,
    store,
})
class CalendarListStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: CalendarListDataResponse | null = null;
    allEventResponse: CalendarListDataResponse | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: CalendarListDataResponse) {
        this.response = payload;
    }

    @Mutation
    setAllEventResponse(payload: CalendarListDataResponse) {
        this.allEventResponse = payload;
    }

    @Action
    retrieveEvents(payload: Partial<FetchEventsParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.get<CalendarListResponse>(`/calendar`, {
            params: payload,
        }).then((response) => {
            if (response.data.code === 200) {
                // response.data is the HTTP's data response.
                this.context.commit(`setResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching events. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    retrieveEventsByFilters(payload: Partial<FetchEventsParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setAllEventResponse`, null);
        axios.get<CalendarListResponse>(`/calendar`, {
            params: payload,
        }).then((response) => {
            if (response.data.code === 200) {
                // response.data is the HTTP's data response.
                this.context.commit(`setAllEventResponse`, response.data.data);
            } else {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching events. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(CalendarListStore);