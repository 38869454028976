
























import { ChecklistItem } from '@/models/miscellaneous';
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
@Component({})
export default class DropdownCheckboxFilter extends Vue {
  @Prop({ required: true })
  dropdownTitle!: string;

  @Prop({ required: true })
  dropdownData!: ChecklistItem[];

  @Prop()
  previousValues!: ChecklistItem[];

  selectedValues: ChecklistItem[] = [];

  isActive = false;

  // Close Dropdown function
  closeDropdown(event: any) {
    if (!this.$el.contains(event.target)) {
      this.isActive = false;
    }
  }

  created() {
    document.addEventListener('click', this.closeDropdown);
  }

  beforeDestroy() {
    document.removeEventListener(`click`, this.closeDropdown);
  }

  @Watch(`previousValues`)
  updateCheckboxes() {
    this.selectedValues = this.previousValues;
  }

  @Emit(`update`)
  returnCheckboxes() {
    return this.selectedValues;
  }
}
