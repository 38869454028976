





























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import DashboardPage from '@/layouts/DashboardPage.vue';
import { AnnouncementItem } from '@/models/announcements';
import moment from 'moment';
import { AgendaItem } from '@/models/agenda';
import MainCalendar from '@/components/timetable/MainCalendar.vue';
import { CalendarStores } from '@/store/calendar';
import { ChecklistItem } from '@/models/miscellaneous';
import DropdownCheckboxFilter from '@/components/common/DropdownCheckboxFilter.vue';
import { MeetingStores } from '@/store/meeting';
import { FetchEventsParams } from '@/models/calendar';

@Component({
  components: {
    DashboardPage,
    MainCalendar,
    DropdownCheckboxFilter,
  },
})
export default class TimetablePage extends Vue {
    //Store
    calendarListStore = CalendarStores.list;
    calendarFilterStore = CalendarStores.filter;
    meetingGetStore = MeetingStores.get;
    // Agenda Calendar
    selectedDates: Date[] = [];
    currentDate: Date = new Date();
    // Agenda List
    agendaData: AgendaItem[] = [];
    monthlyAgendaData: AgendaItem[] = []; // This is for the big calendar
    // Announcement Data
    announcementData: AnnouncementItem[] = [];
    // Filter Data
    subjectData: ChecklistItem[] = [];
    formData: ChecklistItem[] = [];
    languageData: ChecklistItem[] = [];
    // Filter Checkbox Selection
    subjectCheckbox: ChecklistItem[] = [];
    formCheckbox: ChecklistItem[] = [];
    languageCheckbox: ChecklistItem[] = [];

    get subjects() {
        return this.subjectData;
    }

    get levels() {
        return this.formData;
    }

    get languages() {
        return this.languageData;
    }

    get dates() {
        return this.selectedDates;
    }

    get currentMonth() {
        return moment(this.currentDate).format(`MMMM`);
    }

    get currentYear() {
        return moment(this.currentDate).format(`YYYY`);
    }

    get agendas() {
        return this.agendaData;
    }

    get announcements() {
        return this.announcementData;
    }

    get mainCalendarInitialDate() {
        return this.currentDate;
    }

    get subjectFilterQuery() {
        const query: number[] = [];
        this.subjectCheckbox.forEach((data) => {
            query.push(data.id);
        });
        return query.join(',');
    }

    get levelFilterQuery() {
        const query: string[] = [];
        this.formCheckbox.forEach((data) => {
            query.push(data.value);
        });
        return query.join(',');
    }

    get languageFilterQuery() {
        const query: number[] = [];
        this.languageCheckbox.forEach((data) => {
            query.push(data.id);
        });
        return query.join(',');
    }

    mounted() {
        this.currentDate = new Date();
        this.fetchEvents();
    }

    @Watch(`calendarFilterStore.response`)
    populateFilters() {
        if (this.calendarFilterStore.response) {
            this.subjectData = [];
            this.formData = [];
            this.languageData = [];
            this.calendarFilterStore.response.objects.subjects.forEach((data) => {
                this.subjectData.push({ id: data.id, value: data.subject_name });
            });
            this.calendarFilterStore.response.objects.forms.forEach((data, index) => {
                this.formData.push({ id: index, value: data });
            });
            this.calendarFilterStore.response.objects.languages.forEach(( data ) => {
                this.languageData.push({ id: data.id, value: data.name });
            });
        }
    }

    @Watch(`calendarListStore.response`)
    updateCalendarEvents() {
        if (this.calendarListStore.response) {
            this.agendaData = [];
            // Sort
            this.calendarListStore.response.objects.sort((a, b) => {
                return moment(a.start_time).toDate().getTime() - moment(b.start_time).toDate().getTime();
            });
            this.calendarListStore.response.objects.forEach((data) => {
                const agendaIndex = this.agendaData.findIndex((item) => {
                    return moment(item.date).format(`DD/MM/YYYY`) === moment(data.start_time).format(`DD/MM/YYYY`);
                });
                // If a date exist previously,
                if (agendaIndex > -1) {
                    this.agendaData[agendaIndex].agendaDetails.push({ 
                        id: data.id,
                        eventId: data.eventId,
                        color: data.courseColor, 
                        text: data.courseName, 
                        startTime: moment(data.start_time).toDate() , 
                        endTime: moment(data.end_time).toDate(),
                    });
                } 
                
                if (agendaIndex < 0 && data !== null) {
                    this.agendaData.push({
                        date: moment(data.start_time).toDate(),
                        agendaDetails: [
                            {
                                id: data.id,
                                eventId: data.eventId,
                                color: data.courseColor,
                                text: data.courseName,
                                startTime: moment(data.start_time).toDate() , 
                                endTime: moment(data.end_time).toDate(),
                            }
                        ],
                    });
                }
            });
        }
    }

    @Watch(`calendarListStore.allEventResponse`)
    updateCalendarMonthlyEvents() {
        if (this.calendarListStore.allEventResponse) {
            this.monthlyAgendaData = [];
            this.calendarListStore.allEventResponse.objects.forEach((data) => {
                const agendaIndex = this.monthlyAgendaData.findIndex((item) => {
                    return moment(item.date).format(`DD/MM/YYYY`) === moment(data.start_time).format(`DD/MM/YYYY`);
                });
                // If a date exist previously,
                if (agendaIndex > -1) {
                    this.monthlyAgendaData[agendaIndex].agendaDetails.push({ 
                        id: data.id,
                        eventId: data.eventId,
                        color: data.courseColor, 
                        text: data.courseName, 
                        startTime: moment(data.start_time).toDate() , 
                        endTime: moment(data.end_time).toDate(),
                    });
                } else {
                    this.monthlyAgendaData.push({
                        date: moment(data.start_time).toDate(),
                        agendaDetails: [
                            {
                                id: data.id,
                                eventId: data.eventId,
                                color: data.courseColor,
                                text: data.courseName,
                                startTime: moment(data.start_time).toDate() , 
                                endTime: moment(data.end_time).toDate(),
                            }
                        ],
                    });
                }
            });
        }
    }

    updateDateStartRange(date: Date) {
        if (this.selectedDates.length === 0) {
            this.selectedDates.push(date);
        } else {
            this.selectedDates[0] = date;
        }
    }

    updateDateEndRange(date: Date) {
        this.selectedDates[1] = date;
        const startDate = moment(this.selectedDates[0]).format(`YYYY-MM-DDTHH:mm`);
        const endDate = moment(this.selectedDates[1]).endOf(`d`).format(`YYYY-MM-DDTHH:mm`);
        if (moment(endDate).diff(moment(startDate)) > 0) {
            this.calendarListStore.retrieveEvents({ 
                from: startDate, 
                to: endDate,
                formName: this.levelFilterQuery,
                subjectid: this.subjectFilterQuery,
                languageid: this.languageFilterQuery
            });
        } else {
            const reversedStartDate = moment(endDate).startOf(`d`).format(`YYYY-MM-DDTHH:mm`);
            const reversedEndDate = moment(startDate).endOf(`d`).format(`YYYY-MM-DDTHH:mm`);
            this.calendarListStore.retrieveEvents({ 
                from: reversedStartDate, 
                to: reversedEndDate,
                formName: this.levelFilterQuery,
                subjectid: this.subjectFilterQuery,
                languageid: this.languageFilterQuery
            });
        }
    }

    // Format 
    formatAnnouncementDate(date: Date) {
        return moment(date).format(`do MMM YYYY, HH:mm`);
    }

    formatAgendaDate(date: Date) {
        
        if (moment(date).format(`DD/MM/YYYY`) === moment(new Date()).format(`DD/MM/YYYY`)) {
            return moment(date).format(`[Today], DD/MM/YYYY`);
        }

        if (moment(date).format(`DD/MM/YYYY`) === moment(new Date()).add(1, `days`).format(`DD/MM/YYYY`)) {
            return moment(date).format(`[Tomorrow], DD/MM/YYYY`);
        }

        return moment(date).format(`dddd, DD/MM/YYYY`);
    }

    formatAgendaItemDate(date: Date) {
        return moment(date).format(`hh:mm a`);
    }

    // Navigation Functions
    incrementMonth() {
        this.currentDate = moment(this.currentDate).add(1, `month`).toDate();
        const startDate = moment(this.currentDate).startOf(`month`);
        const endDate = moment(this.currentDate).endOf(`month`).endOf(`d`);
        this.calendarListStore.retrieveEventsByFilters({ 
            from: startDate.format(`YYYY-MM-DDTHH:mm`), 
            to: endDate.format(`YYYY-MM-DDTHH:mm`),
            formName: this.levelFilterQuery,
            subjectid: this.subjectFilterQuery,
            languageid: this.languageFilterQuery,
        });
    }

    decrementMonth() {
        this.currentDate = moment(this.currentDate).subtract(1, `month`).toDate();
        const startDate = moment(this.currentDate).startOf(`month`);
        const endDate = moment(this.currentDate).endOf(`month`).endOf(`d`);
        this.calendarListStore.retrieveEventsByFilters({ 
            from: startDate.format(`YYYY-MM-DDTHH:mm`), 
            to: endDate.format(`YYYY-MM-DDTHH:mm`),
            formName: this.levelFilterQuery,
            subjectid: this.subjectFilterQuery,
            languageid: this.languageFilterQuery,
        });
    }

    // Get meeting link
    findMeetingLink(eventId: number) {
        this.meetingGetStore.retrieveKalturaMeetingRoom(eventId);
    }

    @Watch(`meetingGetStore.response`)
    openKalturaRoom() {
        if (this.meetingGetStore.response && this.meetingGetStore.response.objects.link) {
            const url = this.meetingGetStore.response.objects.link;
            setTimeout(() => {
                window.open(url, '_blank')!.focus();
            });
        }
    }

    // Update Filters
    resetFilters() {
        this.subjectCheckbox = [];
        this.formCheckbox = [];
        this.languageCheckbox = [];
        this.fetchEvents();
    }

    updateSubjectFilters(data: ChecklistItem[]) {
        this.subjectCheckbox = data;
        this.fetchEvents();
    }

    updateFormFilters(data: ChecklistItem[]) {
        this.formCheckbox = data;
        this.fetchEvents();
    }

    updateLanguageFilters(data: ChecklistItem[]) {
        this.languageCheckbox = data;
        this.fetchEvents();
    }

    fetchEvents() {
        let startDate = moment(this.currentDate).startOf(`week`).add(1, `d`);
        let endDate = moment(this.currentDate).endOf(`week`).add(1, `d`).endOf(`d`);
        this.selectedDates = [startDate.toDate(), endDate.toDate()];
        const retrieveEventParams: Partial<FetchEventsParams> = { 
            from: startDate.format(`YYYY-MM-DDTHH:mm`), 
            to: endDate.format(`YYYY-MM-DDTHH:mm`),
        };
        if (this.levelFilterQuery.length > 0) {
            retrieveEventParams.formName = this.levelFilterQuery;
        }
        if (this.subjectFilterQuery.length > 0) {
            retrieveEventParams.subjectid = this.subjectFilterQuery;
        }
        if (this.languageFilterQuery.length > 0) {
            retrieveEventParams.languageid = this.languageFilterQuery;
        }
        this.calendarListStore.retrieveEvents(retrieveEventParams);
        startDate = moment(this.currentDate).startOf(`month`);
        endDate = moment(this.currentDate).endOf(`month`).endOf(`d`);
        this.calendarFilterStore.retrieveFilters();
        const retrieveEventsByFiltersParams: Partial<FetchEventsParams> = { 
            from: startDate.format(`YYYY-MM-DDTHH:mm`), 
            to: endDate.format(`YYYY-MM-DDTHH:mm`),
        };
        if (this.levelFilterQuery.length > 0) {
            retrieveEventsByFiltersParams.formName = this.levelFilterQuery;
        }
        if (this.subjectFilterQuery.length > 0) {
            retrieveEventsByFiltersParams.subjectid = this.subjectFilterQuery;
        }
        if (this.languageFilterQuery.length > 0) {
            retrieveEventsByFiltersParams.languageid = this.languageFilterQuery;
        }
        this.calendarListStore.retrieveEventsByFilters(retrieveEventsByFiltersParams);
    }

}
