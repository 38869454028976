import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { MeetingItemResponse, MeetingResponse } from '@/models/meeting';

@Module({
    namespaced: true,
    dynamic: true,
    name: `meeting.get`,
    store,
})
class MeetingGetStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: MeetingItemResponse | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: MeetingItemResponse) {
        this.response = payload;
    }

    @Action
    retrieveKalturaMeetingRoom(eventId: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.post<MeetingResponse>(`/meeting/link`, {
            eventId
        }).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
            }
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 5000, // 3 Seconds
                    message: `Event is not available.`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(MeetingGetStore);