




import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar, { CalendarApi, CalendarOptions, EventInput } from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { AgendaItem } from '@/models/agenda';
@Component({
  components: { FullCalendar },
})
export default class MainCalendar extends Vue {
  @Prop({ required: true })
  events!: AgendaItem[];

  @Prop({ required: true })
  initialDate!: Date;

  currentInitialDate: Date = new Date();
  currentEvents: EventInput[] = [];

  get calendarOptions(): CalendarOptions {
    return this.calendarConfig;
  }

  get calendarEvents() {
    return this.currentEvents;
  }

  calendarConfig: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: `dayGridMonth`,
    headerToolbar: {
      left: `title`,
      center: ``,
      right: ``,
    },
    initialDate: this.initialDate,
    events: this.calendarEvents,
    firstDay: 1,
    eventDisplay: `block`,
    eventClick: (info) => {
      this.findMeetingLink(info.event.id);
    },
  };

  mounted() {
    this.currentInitialDate = this.initialDate;
    this.currentEvents = [];
  }

  @Watch(`initialDate`)
  moveCalendarPage() {
    const calendar = this.$refs.calendar as any;
    const calendarApi: CalendarApi = calendar.getApi();
    calendarApi.gotoDate(this.initialDate);
  }

  @Watch(`events`)
  updateCalendarEvents() {
    this.currentEvents = [];
    let groupId = 0;
    this.events.forEach((data) => {
      data.agendaDetails.forEach((details) => {
        this.currentEvents.push({
          id: details.eventId.toString(),
          groupId: groupId.toString(),
          start: details.startTime,
          end: details.endTime,
          color: details.color,
          title: details.text,
          extendedProps: {
            itemId: details.id,
          },
        });
      });
      groupId++;
    });
    const calendar = this.$refs.calendar as any;
    const calendarApi: CalendarApi = calendar.getApi();
    calendarApi.removeAllEvents();
    calendarApi.addEventSource(this.currentEvents);
  }

  @Emit(`getMeeting`)
  findMeetingLink(eventId: string) {
    return Number(eventId);
  }
}
